<template>
  <div class="kt-section kt-section--first w-100">
    <div class="kt-form kt-form--label-right w-100">

      <h3 class="kt-section__title mb-3"> Size table header position </h3>

      <p class="mb-20">Set header position for your table. This data need for pretty Size Guide formatting.</p>

      <div class="kt-checkbox-inline text-center">
        <label for="top" class="kt-checkbox kt-checkbox--primary">
          <input type="checkbox" id="top" :checked="top"
                 @change="(e) => updateTables({top: e.target.checked})">
          Top row
          <span/>
        </label>
        <label for="bottom" class="kt-checkbox kt-checkbox--primary">
          <input type="checkbox" id="bottom" :checked="bottom"
                 @change="(e) => updateTables({bottom: e.target.checked})">
          Bottom Row
          <span/>
        </label>
        <label for="left" class="kt-checkbox kt-checkbox--primary">
          <input type="checkbox" id="left" :checked="left"
                 @change="(e) => updateTables({left: e.target.checked})">
          Left column
          <span/>
        </label>
        <label for="right" class="kt-checkbox kt-checkbox--primary">
          <input type="checkbox" id="right" :checked="right"
                 @change="(e) => updateTables({right: e.target.checked})">
          Right column
          <span/>
        </label>
      </div>

      <div id="table_section" class="mb-20">
        <div class="table_block">
          <div class="table_block-header">
            <h3 class="kt-section__title mb-3">Table builder</h3>

            <button title="Add column" class="btn btn-icon btn-primary btn-circle btn-sm" @click="addColumn">
              <i class="fas fa-plus"></i>
            </button>

            <p :style="{opacity: errors_msg.fill_table ? 1: 0}" class="error">Please, fill correctly all fields in
              table</p>
          </div>

          <div v-bind:class="`${errors_msg.fill_table && 'error-block'} builder-block`">
            <div class="warning_block">
              <div class="warning" v-if="errors_msg.fill_table_body">
                <div class="exclamation_icon"/>
                Some cells don't match the input format (Ex: 22.5 or 22.5-23.5)
                We recommend to fix them or to set the correct header positions for this table.
              </div>
            </div>

            <div class="table_content w-100">
              <table class="w-100">
                <tbody>
                <tr v-for="(row, i) in table_data" :key="`row_${i}`">
                  <td v-for="(col, j) in row" :key="`col_${j}`">
                    <input
                        v-if="checkTitleCell(i, j)" :value="table_data[i][j][0]"
                        v-on:input="(e) => onChangeTableCell(e, i, j)" type="text"
                        v-bind:class="`${!table_data[i][j].length && 'error_input '} input_title form-control`"
                    />
                    <input
                        v-else :value="table[i][j][0]"
                        v-on:input="(e) => onChangeTableCell(e, i, j)" type="text"
                        title="Input number ex: 22.5 or range ex: 22.5-23.5"
                        pattern="((\d+((,|.)\d+)?)(-\d((,|.)\d+)?)?)+"
                        v-bind:class="`${!table_data[i][j][0].length && 'error_input '} input_value form-control`"
                    />
                  </td>
                  <td class="remove_cell remove_col">
                    <button title="Remove row" class="btn btn-icon btn-danger btn-circle btn-sm" @click="removeRow(i)">
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td v-for="(col, i) in table[0]" class="remove_cell" :key="`col_${i}`">
                    <button title="Remove column" class="btn btn-icon btn-danger btn-circle btn-sm" @click="removeColumn(i)">
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


            <button v-if="this.table.length"  title="Add row" class="btn btn-icon btn-primary btn-circle btn-sm mr-4" @click="addRow">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {UPDATE_TABLES} from "../../../store/modules/guides/types";
import {createNewArray, debounce} from "../../../services";

export default {
  name: "Table",
  props: {errors_msg: Object},

  data: () => ({
    table_data: []
  }),

  computed: {
    ...mapState({
      table: state => state.guides.table && state.guides.table.body ? state.guides.table.body : [],
      top: state => state.guides.table.top,
      bottom: state => state.guides.table.bottom,
      left: state => state.guides.table.left,
      right: state => state.guides.table.right,
    }),
  },

  methods: {
    ...mapMutations({
      updateTables: `guides/${UPDATE_TABLES}`
    }),

    addRow: function () {
      let new_table = createNewArray(this.table);
      let new_row = [];

      if (new_table.length) {
        for (let i = 0; i < new_table[0].length; i++) {
          new_row.push(['']);
        }
        new_table.push(new_row);
      } else {
        new_table.push([['']]);
      }

      this.updateTables({body: new_table});

    },
    addColumn: function () {
      let new_table = createNewArray(this.table);

      if (new_table.length) {
        for (let i = 0; i < new_table.length; i++) {
          new_table[i].push(['']);
        }
      } else {
        new_table.push([['']]);
      }

      this.updateTables({body: new_table});
    },
    removeRow: function (index) {
      let new_table = createNewArray(this.table);

      new_table.splice(index, 1);

      this.updateTables({body: new_table});
    },
    removeColumn: function (index) {
      let new_table = createNewArray(this.table);

      if (new_table[0].length === 1) {
        new_table = [];
      } else {
        for (let i = 0; i < new_table.length; i++) {
          new_table[i].splice(index, 1);
        }
      }
      this.updateTables({body: new_table});

    },

    onChangeTableTitle: function () {
    },


    checkTitleCell: function (i, j) {
      if (this.top && i === 0) {
        return true
      }
      if (this.bottom && i === this.table.length - 1) {
        return true
      }
      if (this.right && j === this.table[i].length - 1) {
        return true
      }
      if (this.left && j === 0) {
        return true
      }

      return false;
    },

    onChangeTableCell: function (e, i, j) {
      e.preventDefault();
      this.table_data[i][j][0] = e.target.value;
      this.onWaitChangeInput();
    },

    onWaitChangeInput: function () {
      let new_table = createNewArray(this.table_data);
      this.updateTables({body: new_table});
    },
  },
  watch: {
    table(newValue) {
      this.table_data = createNewArray(newValue);
    }
  },
  created: function () {
    this.onWaitChangeInput = debounce(this.onWaitChangeInput, 500);
    this.table_data = createNewArray(this.table);
  }

}
</script>

<style scoped>

</style>