<template>
  <div class="kt-section kt-section--first w-100 card p-3" id="info_section" ref="info_section">
    <div class="kt-form w-100">
      <div class="row mb-3">
        <div class="col d-flex">
          <h3 class="kt-section__title mb-0 my-auto"> Size guide info: </h3>
        </div>
        <div class="col">
          <div class="popover-block templates-popover-block right-popover dropdown-list-block">
            <TemplatesList/>
          </div>
        </div>
      </div>

      <p v-show="errors_msg.input_name" class="error text-center">Please, input name for size guide</p>
      <div :class="`${errors_msg.input_name && 'error-block'} form-group row`">
        <label class="col-12 col-form-label pt-0">Size guide name</label>
        <div class="col-12">
          <input v-model="table_name" type="text"
                 v-bind:class="`${!table_name.length && 'error_input '} form-control`" :maxlength="64"
                 @input="onChangeBaseInput"
          />
        </div>
      </div>

      <p v-show="errors_msg.input_title" class="error text-center">Please, input title for size guide</p>
      <div :class="`${errors_msg.input_title && 'error-block'} form-group row`">
        <label class="col-12 col-form-label pt-0">Size guide title</label>
        <div class="col-12">
          <input v-model="title" type="text" v-bind:class="`${!title.length && 'error_input '} form-control`"
                 :maxlength="64"
                 @input="onChangeInput"
          />
        </div>
      </div>

      <div :class="`form-group row`">
        <label class="col-12 col-form-label pt-0">Size guide subtitle</label>
        <div class="col-12">
          <input v-model="subtitle" type="text" class="form-control"
                 @input="onChangeInput"
          />
        </div>
      </div>
      <div class="mb-3">
        <div class="can-toggle switcher">
          <input id="unit" type="checkbox"
                 true-value="in"
                 false-value="cm"
                 v-model="unitValue">
          <label for="unit">
            <div class="can-toggle__switch" data-checked="inches" data-unchecked="cm"/>
          </label>
        </div>
      </div>

      <table-component :errors_msg="errors_msg" />

      <div :class="`form-group row`">
        <label class="col-12 col-form-label">Size guide description</label>
        <div class="col-12">
          <vue-editor
              v-model="description"
              :editorToolbar="customToolbar"></vue-editor>
        </div>
      </div>

      <div class="image-input">
        <div class="image-input_block">
          <div class="choose_image_btn" @click="triggerInput">
            {{ image ? 'Change description image' : 'Add image to description' }}
          </div>
          <div class="image-input__image" v-bind:style="{backgroundImage: `url(${image})`}"></div>
          <div v-if="image" class="remove_image">
            <button title="Remove image" class="btn btn-icon btn-light btn-circle btn-sm" @click="clearImage">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <input ref="fileInput" accept="image/png,image/gif,image/jpeg" @change="previewThumbnail"
                 class="image-input__input"
                 name="thumbnail" type="file">
        </div>
      </div>

      <div class="popover-block right-popover template-button-block d-flex">
        <div class="ml-auto">
          <button v-if="template_id" class="btn btn-secondary mr-2" data-hover="Reset guide to initial state"
                  @click="onClearTemplateDetails">Clear guide
          </button>
          <button v-popover:template.bottom class=" btn btn-primary tip" data-hover="Click for save as template">Save as template
          </button>
        </div>
        <popover name="template">
          <label for="template_name">Set Template name</label>
          <input id="template_name" type="text" class="form-control" v-model="template_name" :maxlength="128"/>
          <button v-on:click="saveTemplate" :disabled="!template_name" class="btn btn-primary w-100 mt-2">Save as template</button>
        </popover>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState, mapActions} from 'vuex';
import TemplatesList from "./TemplatesList";
import TableComponent from './Table'
import {debounce} from "../../../services";
import {CREATE, SET_GUIDE, SET_TEMPLATE, UPDATE_GUIDE, UPDATE_TABLES} from "../../../store/modules/guides/types";
import {VueEditor} from "vue2-editor";
import {LIST_NAMES} from "../../../store/modules/guides/interfaces";
import {requestStatus} from "@/services/services";
import {clearGuide} from "@/services/defaultData"

export default {
  name: "BaseInfo",
  components: {TemplatesList, TableComponent, VueEditor},
  props: {
    errors_msg: Object
  },
  data: () => ({
    table_name: '',
    title: '',
    subtitle: '',
    description: '',
    customToolbar: [
      [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
      ['bold', 'italic', 'underline'],
      [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'color': []}, {'background': []}],
      ['link'],
    ],
    image: "",
    template_name: "",
    unitValue: ""
  }),
  computed: {
    ...mapState({
      shopSettings: state => state.shop.settings,
      templates: state => state.guides.list_templates,
      template_id: state => state.guides.templates_details ? state.guides.templates_details.id : null,
      guide: state => state.guides.guide,
      table: state => state.guides.table,
      isEdit: state => state.guides.isEdit,
      unit: state => state.guides.guide.unit,
      status: state => state.guides.status,

    }),
  },

  methods: {
    ...mapActions({
      create: `guides/${CREATE}`,
    }),
    ...mapMutations({
      updateGuide: `guides/${UPDATE_GUIDE}`,
      updateTables: `guides/${UPDATE_TABLES}`,
      setGuide: `guides/${SET_GUIDE}`,
      setTemplate: `guides/${SET_TEMPLATE}`,
    }),

    onChangeInput: function (e) {
      e.preventDefault();
      this.onWaitChangeInput();
    },

    onChangeSwitcher: function (e) {
      console.log(e);
      this.updateGuide({unit: e.target.checked})
    },


    onWaitChangeInput: function () {
      this.updateTables({
        title: this.title,
        subtitle: this.subtitle,
        description: this.description,
        image: this.image
      });
    },

    onChangeBaseInput: function (e) {
      e.preventDefault();
      this.onWaitChangeBaseInput();
    },

    onWaitChangeBaseInput: function () {
      this.updateGuide({
        table_name: this.table_name
      });
    },

    previewThumbnail: function (event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.image = e.target.result;
          this.onWaitChangeInput();
        }

        reader.readAsDataURL(input.files[0]);
      }
    },

    clearImage: function () {
      this.image = '';
      this.onWaitChangeInput();
    },

    triggerInput: function () {
      this.$refs.fileInput.click();
    },

    saveTemplate: function () {
      let data = {
        table: this.table,
        name: this.template_name,
        unit: this.guide.unit
      };
      this.$refs.info_section.click();

      const listName = LIST_NAMES.Templates;
      this.create({listName, data});
    },
    onClearTemplateDetails: function () {
      this.setGuide(clearGuide);
      this.setTemplate(null);
    }

  },

  watch: {
    guide(newValue) {
      this.table_name = newValue.table_name || '';
    },

    unit(newValue) {
      this.unitValue = newValue;
    },

    unitValue(newValue) {
      this.updateGuide({unit: newValue})
    },

    table(newValue) {
      this.title = newValue.title;
      this.subtitle = newValue.subtitle;
      this.description = newValue.description;
      this.image = newValue.image;
    },

    description() {
      this.onWaitChangeInput();
    },


    status(newValue) {
      if (newValue.change_field === 'create_templates' && newValue.create_templates === requestStatus.success) {
        this.$toaster.success("Success create template");
        this.template_name = "";
      }
    }
  },
  created: function () {
    this.onWaitChangeInput = debounce(this.onWaitChangeInput, 500);
    this.onWaitChangeBaseInput = debounce(this.onWaitChangeBaseInput, 500);
  }
}
</script>

<style scoped>

</style>