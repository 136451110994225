<template>
  <article>
    <div class="card kt-section p-3">
      <div>
        <h3 class="kt-section__title mb-3">Per product</h3>
        <div class="form-group">
          <div class="kt-input-icon kt-input-icon--left">
            <span class="kt-input-icon__icon">
              <i class="fas fa-search m-auto"></i>
            </span>
            <input type="text" class="form-control" v-model="search_value" placeholder="Search products"
                   @input="onChangeInput">
          </div>

        </div>
        <h4 class="kt-section__title mb-3">Filter by</h4>
        <div class="form-group">
          <products-filter
              :product_type="product_type"
              :vendor="vendor"
              :tag="tag"
              :change-value="onChangeValue"
          />

          <div class="warning mt-3" v-if="has_table">
            <div class="exclamation_icon"/>
            You want to attach the size guide to products that already have a table. For these products, the size guide
            will be replaced.
          </div>

          <div class="warning mt-3" v-if="select_all_on_page">
            <div class="exclamation_icon"/>
            Size Guide will be added only for selected products. If you want to attach the guide to the future added
            products, use Bulk attachment form above.
          </div>

        </div>
        <div class="row flex">

          <div class="col-6 ">
            <div class="products-block" v-if="count">
              <h3 class="kt-section__title mb-3">All found products ({{ count }})</h3>
              <table class="product-table">
                <thead>
                <tr>
                  <th>
                    <input title="Select all products on current page" type="checkbox" v-model="select_all_on_page"
                           @change="toggleSelectedAllOnPage">
                  </th>
                  <th></th>
                  <th>Product</th>
                  <th>Type</th>
                  <th>Vendor</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="product in products" v-bind:class="checkedProduct(product.id)" :key="product.id">
                  <td>
                    <input type="checkbox" :value="product" v-model="attachedProducts"
                           @change="checkSelectedAll">
                  </td>
                  <td>
                    <img width="35" height="35" alt="" v-bind:src="product.image">
                  </td>
                  <td>
                    <div class="product-title">
                      <span v-if="product.size_table && product.size_table!==table_id"
                            title="This product already has Size Guide" class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
                      {{ product.title }}
                    </div>
                    <span class="btn-sm btn btn-label btn-label-info mr-1" v-for="tag in product.tags" :key="tag"> {{ tag }} </span>
                  </td>
                  <td>{{ product.product_type }}</td>
                  <td>{{ product.vendor }}</td>
                </tr>
                </tbody>
              </table>

              <Pagination
                  :change-page="onChangeProductsPage"
                  :count="count"
                  :default-page="1"
                  :limit="limit_products_page"
              />
            </div>
            <div v-else class="text-center">
              <div v-if="!this.search_value">
                <div><i class="not-found-icon icon-search"></i></div>
                <h3>You haven`t products yet</h3>
                <p>Please, add some product to your shop</p>
              </div>
              <div v-else>
                <div><i class="not-found-icon icon-search"></i></div>
                <h3>Could not find any products</h3>
                <p>Try changing the filter or search term</p>
              </div>
            </div>
          </div>

          <div class="col-6" v-if="attachedProducts.length > 0">
            <div class="kt-section products-block">
              <h3 class="kt-section__title mb-3"> Attached {{ countedSelectedProducts }} product{{ countedSelectedProducts > 1 ? 's' : '' }} </h3>
              <table class="attached-table">
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Product</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="product in printAttachedProducts"
                    :key="product.id">
                  <td><input type="checkbox" v-bind:value="product" v-model="attachedProducts"
                             @change="unattachedProducts(product.id)"></td>
                  <td>
                    <img width="35" height="35" alt="" v-bind:src="product.image">
                  </td>
                  <td>{{ product.title }}</td>
                </tr>
                </tbody>
              </table>

              <Pagination
                  :change-page="onChangeAttacheProductsPage"
                  :count="attachedProducts.length"
                  :default-page="1"
                  :limit="limit_products_page"
              />

            </div>

          </div>

        </div>

      </div>
    </div>

  </article>

</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {
  buildArrayByIds,
  calculatePagesCount,
  concatArrayWithoutDuplication,
  debounce, subtractionArray
} from "../../../services";
import {LIST_NAMES} from "../../../store/modules/guides/interfaces";
import {LOAD, UPDATE_FILTERS} from "../../../store/modules/guides/types";
import Pagination from "../../../components/Pagination";
import ProductsFilter from "./ProductsFilter";
import {requestStatus} from "@/services/services";

export default {
  name: "Products",
  components: {Pagination, ProductsFilter},
  props: {errors_msg: Object},
  data: () => ({
    attachedProducts: [],
    select_all_on_page: false,
    table_id: null,
    offset_products_page: 0,
    limit_products_page: 25,
    offset_attached_page: 0,
    current_page: 0,
    search_value: "",
    tag: "",
    product_type: "",
    vendor: "",
    has_table: false,
  }),
  computed: {
    ...mapState({
      products: state => state.guides.list_products,
      count: state => state.guides.products_count,
      status: state => state.guides.status,
      filters: state => state.guides.filters || {}
    }),

    printAttachedProducts: function () {
      return this.attachedProducts.slice(this.offset_attached_page, this.offset_attached_page + this.limit_products_page)
    },

    countedSelectedProducts: function () {
      return this.attachedProducts.length
    },
  },

  methods: {
    ...mapActions({
      load: `guides/${LOAD}`,
    }),
    ...mapMutations({
      updateFilter: `guides/${UPDATE_FILTERS}`,
    }),

    checkedProduct: function (id) {
      return this.isItemById(this.attachedProducts, id) ? 'checked' : ''
    },

    isItemById: function (array, id) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
          return true;
        }
      }
      return false;
    },

    unattachedProducts: function (id) {
      let attachedProducts = [];
      for (let i = 0; i < this.attachedProducts.length; i++) {
        if (this.attachedProducts[i].id !== id) {
          attachedProducts.push(this.attachedProducts[i]);
        }
      }
      this.attachedProducts = attachedProducts;
      this.checkSelectedAll();
    },

    checkSelectedAll: function () {
      if (!this.products) {
        return
      }
      this.checkShowMsgHasTable();
      let is_all_product = false;
      for (let i = 0; i < this.products.length; i++) {
        if (this.isItemById(this.attachedProducts, this.products[i].id)) {
          is_all_product = true;
        } else {
          is_all_product = false;
          break;
        }
      }
      this.select_all_on_page = is_all_product;
    },

    toggleSelectedAllOnPage: function () {
      if (this.select_all_on_page) {
        this.attachedProducts = concatArrayWithoutDuplication(this.attachedProducts, this.products);
      } else {
        this.attachedProducts = subtractionArray(this.attachedProducts, this.products);
      }
      this.checkShowMsgHasTable();
    },

    onLoadProducts: function () {
      this.load({
        listName: LIST_NAMES.Products,
        filter: `attached=all&limit=${this.limit_products_page}&offset=${this.offset_products_page}&query=${this.search_value}&tag=${this.tag}&product_type=${this.product_type}&vendor=${this.vendor}`
      });
    },

    onChangeProductsPage: function (newValue) {
      if (newValue !== calculatePagesCount(this.offset_products_page, this.limit_products_page) + 1) {
        this.offset_products_page = (newValue - 1) * this.limit_products_page;
      }
    },

    onChangeAttacheProductsPage: function (newValue) {
      this.offset_attached_page = (newValue - 1) * this.limit_products_page;
    },

    onChangeValue: function (field, newValue) {
      this[field] = newValue || "";
      this.onLoadProducts();
    },

    onWaitChangeInput: function () {
      this.onLoadProducts();
    },


    onChangeInput: function (e) {
      e.preventDefault();
      this.onWaitChangeInput();
    },


    checkShowMsgHasTable: function () {
      if (this.products && this.attachedProducts.length) {
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].size_table && this.products[i].size_table !== this.table_id && this.isItemById(this.attachedProducts, this.products[i].id)) {
            this.has_table = true;
            return;
          }
        }
      }
      this.has_table = false;
    },

  },

  watch: {
    offset_products_page() {
      this.onLoadProducts();
    },

    products() {
      this.checkSelectedAll();
    },

    attachedProducts(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.updateFilter({
          products: buildArrayByIds(newValue) || [],
          product_type: "",
          tag: "",
          vendor: "",
        });
      }
    },

    status(newValue) {
      if (
          newValue.change_field === "load_details_tables" &&
          newValue.load_details_tables === requestStatus.success
      ) {
        if (this.filters.products) {
          this.attachedProducts = concatArrayWithoutDuplication(this.attachedProducts, this.products.filter(({id}) => {
            return this.filters.products.includes(id);
          }))
        }
      }
    },
  },
  created() {
    this.onWaitChangeInput = debounce(this.onWaitChangeInput, 500);
  }
}
</script>

<style scoped>

</style>