<template>
  <div class="kt-portlet__body">
    <div class="full-width">
      <div class="kt-section">
        <article>
          <div class="row">
            <div class="col-8">
              <base-info :errors_msg="errors_msg"/>
            </div>
            <div class="col-4">
              <div id="preview">
                <preview/>
              </div>
            </div>
          </div>

        </article>
        <h3 class="kt-section__title mb-3" id="attached_section"> Select products for this size guide </h3>

        <bulk :errors_msg="errors_msg"/>
        <h3 class="kt-section__title mb-3">Or </h3>

        <Products :errors_msg="errors_msg"/>

        <article>
          <button v-bind:disabled='saving' v-on:click="CreateSizeGuide(false)" class="btn btn-secondary"
                  v-bind:data-hover="`${'Save Size guide as draft'}`">Save as draft
          </button>
          <button v-bind:disabled='saving' v-on:click="CreateSizeGuide(true)" class="btn btn-primary ml-3"
                  v-bind:data-hover="`${guide.id ? 'Save Size guide': 'Create Size guide'}`">
            {{ guide.id ? 'Save Size guide and publish' : 'Create Size guide and publish' }}
          </button>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import Preview from './Preview'
import BaseInfo from './BaseInfo'
import Bulk from './Bulk'
import Products from './Products'
import {LOAD_PRODUCTS} from "../../../store/modules/shop/types";
import {CREATE, LOAD, SET_GUIDE, LOAD_DETAILS, LOAD_GUIDE_DETAILS, EDIT} from "../../../store/modules/guides/types";
import {LIST_NAMES} from "../../../store/modules/guides/interfaces";
import {clearGuide} from "@/services/defaultData"
import {requestStatus} from "@/services/services";
import {SET_SETTINGS} from "../../../store/modules/layout/types";

export default {
  name: 'EditGuide',
  components: {
    Preview,
    BaseInfo,
    Bulk,
    Products
  },
  data: () => ({
    success_msg: "",
    errors_msg: {
      input_name: false,
      input_title: false,
      fill_table: false,
      fill_table_body: false,
      attached_products: false,
      saving: false,
      saving_template: false,
      has_table: false,
      some_has_table: false,
      select_all_on_page: false
    },
    saving: false,

  }),
  computed: {
    ...mapState({
      products: state => state.shop.products,
      status: state => state.shop.status,
      guideStatus: state => state.guides.status,
      table: state => state.guides.table,
      guide: state => state.guides.guide,
      filters: state => state.guides.filters,
      unit: state => state.guides.unit,
      isEdit: state => state.guides.isEdit,
      details: state => state.guides.tables_details,
    }),
  },

  methods: {
    ...mapActions({
      loadproducts: `shop/${LOAD_PRODUCTS}`,
      load: `guides/${LOAD}`,
      create: `guides/${CREATE}`,
      loadDetails: `guides/${LOAD_DETAILS}`,
      loadGuideDetails: `guides/${LOAD_GUIDE_DETAILS}`,
      edit: `guides/${EDIT}`,
    }),

    ...mapMutations({
      setGuide: `guides/${SET_GUIDE}`,
      addSettings: `layout/${SET_SETTINGS}`
    }),

    CreateSizeGuide: function (published) {
      this.errors_msg.input_title = false;
      this.errors_msg.input_name = false;
      this.errors_msg.attached_products = false;

      if(!this.guide.table_name){
        this.errors_msg.input_name = true;
        let element = document.querySelector('#info_section');
        element && element.scrollIntoView();
        return;
      }

      if(!this.table.title){
        this.errors_msg.input_title = true;
        let element = document.querySelector('#info_section');
        element && element.scrollIntoView();
        return;
      }

      if(!this.filters.tag && !this.filters.vendor && !this.filters.product_type && (!this.filters.products || (this.filters.products && !this.filters.products.length))){
        this.errors_msg.attached_products = true;
        let element = document.querySelector('#attached_section');
        element && element.scrollIntoView();
        return;
      }

      const data = {
        table: this.table,
        filters: this.filters,
        ...this.guide,
        published
      }
      if (this.guide.id) {
        this.edit({
          listName: LIST_NAMES.Guides,
          id: this.guide.id,
          data
        })
      } else {
        this.create({listName: LIST_NAMES.Guides, data});
      }
    },

    closeAlert: function (alert, field) {
      this[alert][field] = false;
    },
  },

  watch: {
    products() {
      this.checkSelectedAll();
    },

    guideStatus(newValue) {
      if (
          newValue.change_field === "create_tables" &&
          newValue.create_tables === requestStatus.success
      ) {
        this.$toaster.success("Create guide success");
        this.$router.push({name: 'guides'})
      }
      if (
          newValue.change_field === "edit_tables" &&
          newValue.edit_tables === requestStatus.success
      ) {
        this.$toaster.success("Save guide success");
        this.$router.push({name: 'guides'})
      }
    },

    details(newValue) {
      if (newValue.id) {
        this.setGuide(newValue);
      }
    }
  },

  created: function () {
    this.load({listName: LIST_NAMES.Tags, filter: ''});
    this.load({listName: LIST_NAMES.Vendors, filter: ''});
    this.load({listName: LIST_NAMES.Product_types, filter: ''});

    if (this.$route.params.id) {
      this.loadGuideDetails({id: this.$route.params.id, listName: LIST_NAMES.Guides});
    } else {
      this.setGuide(clearGuide);
      this.load({
        listName: LIST_NAMES.Products,
        filter: `attached=all&limit=25&offset=0`
      });
    }
  },

  beforeMount() {
    this.addSettings({
      title: `${this.$route.params.id ? 'Save' : 'Create'} Size Guide And Publish`,
      onClick: () => this.CreateSizeGuide(true)
    })
  }
}
</script>

<style scoped>
</style>