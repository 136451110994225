<template>
  <div class="d-flex" ref="templates">
    <button class="ml-auto btn btn-primary" v-popover:templates.bottom>Load from template</button>
    <popover name="templates">
      <button v-if="templates_details && templates_details.id" class="btn mb-1 w-100 btn-secondary"
              @click="onClearTemplateDetails()"
      >Clear
      </button>
      <input placeholder="search" v-model="search_template" class="form-control" type="text"/>
      <div class="dropdown-list">
        <div v-for="template in templates" :key="`template-${template.id}`" class="dropdown-item">
          <div class="template-name" @click="onSelectTemplate(template)">{{ template.name }} ({{template.unit}})</div>
          <div v-if="template.shop" class="m-auto" @click="() => {show_delete_confirm = true; templateData=template}"><i
              class="fas fa-trash"></i></div>
        </div>
      </div>
    </popover>

    <confirm-modal
        :onConfirm="()=> {onDeleteClick(templateData.id); show_delete_confirm=false;}"
        :onCancel="()=> { templateData = {}; show_delete_confirm = false; }"
        :text="`Do you really want to delete '${templateData.name}'?`"
        :show="show_delete_confirm"
        title=""
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import {
  LOAD,
  DELETE,
  SET_GUIDE,
  UPDATE_GUIDE, SET_TEMPLATE
} from "../../../store/modules/guides/types";
import {LIST_NAMES} from "../../../store/modules/guides/interfaces";
import ConfirmModal from "../../../components/ConfirmModal";
import {clearGuide} from "@/services/defaultData"
import {requestStatus} from "@/services/services";

const listName = LIST_NAMES.Templates;
export default {
  name: "TemplatesList",
  props: {
    resetTemplate: Function,
  },
  components: {ConfirmModal},
  data: () => ({
    search_template: '',
    show_delete_confirm: false,
    templateData: {}
  }),
  computed: {
    ...mapState({
      templates: state => state.guides.list_templates,
      templates_details: state => state.guides.templates_details,
      isEdit: state => state.guides.isEdit,
      status: state => state.guides.status,
    }),
  },

  methods: {
    ...mapActions({
      load: `guides/${LOAD}`,
      onDelete: `guides/${DELETE}`,
    }),

    ...mapMutations({
      setGuide: `guides/${SET_GUIDE}`,
      updateGuide: `guides/${UPDATE_GUIDE}`,
      setTemplate: `guides/${SET_TEMPLATE}`,
    }),

    onDeleteClick: function (id) {
      this.onDelete({listName, id});
    },

    onSetTemplateData: function (data) {
      let template_data = {...data};
      delete template_data.id;
      this.setGuide(template_data);
      this.setTemplate(data);
      this.$refs.templates.click();
    },

    onSelectTemplate: function (template) {
      this.onSetTemplateData(template);
      this.$refs.templates.click();
    },

    onClearTemplateDetails: function () {
      this.setGuide(clearGuide);
      this.setTemplate(null);
      this.$refs.templates.click();
    }
  },

  watch: {
    status(newValue) {
      if (
          newValue.change_field === "delete_templates" &&
          newValue.delete_templates === requestStatus.success
      ) {
        this.$toaster.success("Delete template success");
      }
    }
  },

  created: function () {
    this.load({listName, filter: ''});
  }
}
</script>

<style scoped>

</style>