<template>
  <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet">
      <edit-guide/>
    </div>
  </div>
</template>

<script>
import EditGuide from './components/EditGuide'
import {mapMutations} from "vuex";
import {CLEAR_DATA} from "../../store/modules/guides/types";

export default {
  name: 'Guide',
  components: {
    EditGuide
  },
  methods: {
    ...mapMutations({
      clearData: `guides/${CLEAR_DATA}`,
    })
  },
  beforeRouteLeave(to, from, next) {
    this.clearData();
    next();
  }

}
</script>

