<template>
  <div class="kt-datatable__pager kt-datatable--paging-loaded crm-pagination">
    <div class="kt-datatable__pager-nav">
      <div v-if="count > limit">
        <b-pagination
            v-model="currentPage"
            :per-page="limit"
            :total-rows="count"
        >
          <span slot="first-text" class="kt-datatable__pager-link--first"
          ><i class="fas fa-angle-double-left"></i></span>
          <span slot="prev-text" class="kt-datatable__pager-link--prev"
          ><i class="fas fa-angle-left"></i></span>
          <span slot="next-text" class="kt-datatable__pager-link--next"
          ><i class="fas fa-angle-right"></i></span>
          <span slot="last-text" class="kt-datatable__pager-link--last"
          ><i class="fas fa-angle-double-right"></i></span>
          <span slot="ellipsis-text" class="kt-datatable__pager-link--last"
          >...</span
          >
          <span slot="page" slot-scope="{ page }">{{ page }}</span>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    count: Number,
    limit: Number,
    changePage: Function,
    defaultPage: Number
  },
  data: () => ({
    currentPage: 1
  }),
  watch: {
    currentPage(newValue) {
      this.changePage(newValue);
    },
    defaultPage(newValue) {
      this.currentPage = newValue;
    }
  },
  created: function() {
    this.currentPage = this.defaultPage || 1;
  }
}
</script>

<style scoped>

</style>