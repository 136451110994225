<template>
  <div class="card preview_block kt-section p-3">
    <h3 class="kt-section__title">Preview Size Guide</h3>
    <p class="size_title">
      {{title}}
    </p>
    <p class="preview_subtitle">
      {{subtitle}}
    </p>

    <div class="d-flex preview_unit_block mb-3">
      <div class="can-toggle switcher mx-auto">
        <input id="unit" type="checkbox"
               true-value="in"
               false-value="cm"
               v-model="unit">
        <label for="unit">
          <div class="can-toggle__switch" data-checked="inches" data-unchecked="cm"></div>
        </label>
      </div>

      <div class="tip disable_block" data-hover="This slider will change units at Product page Size guide"/>
    </div>

    <div class="preview_table-block">
      <table class="preview_table w-100">
        <tbody>
        <tr v-for="(row, i) in table_data" :key="`row${i}`">
          <td v-for="(col, j) in row" :key="`col${j}`">
            <div v-if="checkTitleCell(i, j)" class="title">{{table_data[i][j][0]}}</div>
            <div v-else>{{col[0]}}</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="ql-editor preview_description" v-html="description" />

    <div class="preview_image_block"><img v-if="image" class="preview_image" :src="image" /></div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "preview",

  computed: {
    ...mapState({
      table_data: state => state.guides.table && state.guides.table.body ? state.guides.table.body : [],
      unit: state => state.guides.guide.unit,
      top: state => state.guides.table.top,
      bottom: state => state.guides.table.bottom,
      left: state => state.guides.table.left,
      right: state => state.guides.table.right,
      description: state => state.guides.table.description,
      title: state => state.guides.table.title,
      subtitle: state => state.guides.table.subtitle,
      image: state => state.guides.table.image,
    }),
  },

  methods: {
    checkTitleCell: function (i, j) {
      if(this.top && i === 0){
        return 'title'
      }
      if(this.bottom && i === this.table_data.length-1){
        return 'title'
      }
      if(this.right && j === this.table_data[i].length-1){
        return 'title'
      }
      if(this.left && j === 0){
        return 'title'
      }

      return '';
    }
  }
}
</script>

<style scoped>

</style>
