<template>
  <div class="row flex">

    <div class="col app-select">
      <v-select
          :options="product_types"
          :getOptionLabel="option => option.name"
          :reduce="option => option.name"
          :value="product_type"
          @input="(newValue) => changeValue('product_type', newValue)"
          placeholder="Select Product Types"
      />
    </div>
    <span class="col-auto m-auto">And</span>
    <div class="col app-select">
      <v-select
          :options="vendors"
          :getOptionLabel="option => option.name"
          :reduce="option => option.name"
          :value="vendor"
          @input="(newValue) => changeValue('vendor', newValue)"
          placeholder="Select Vendor"
      />
    </div>
    <span class="col-auto m-auto">And</span>
    <div class="col app-select">
      <v-select
          :options="tags"
          :getOptionLabel="option => option.name"
          :reduce="option => option.name"
          :value="tag"
          @input="(newValue) => changeValue('tag', newValue)"
          placeholder="Select Tag"
      />
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import vSelect from "vue-select";

export default {
  name: "ProductsFilter",
  components: {vSelect},
  props: {
    tag: String,
    vendor: String,
    product_type: String,
    changeValue: Function
  },
  computed: {
    ...mapState({
      vendors: state => state.guides.list_vendors,
      tags: state => state.guides.list_tags,
      product_types: state => state.guides.list_product_types,
    }),
  }
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>