import { ITemplateDetails, ITemplateListItem} from "@/store/modules/guides/interfaces";

export const defaultTemplates: ITemplateListItem[] = [
    {
        "id": 24,
        "name": "new (cm)",
        "is_global": false
    },
    {
        "id": 2,
        "name": "MEN/Jeans&Trousers (in)",
        "is_global": true
    },
    {
        "id": 3,
        "name": "MEN/Inside Legs (cm)",
        "is_global": true
    },
    {
        "id": 4,
        "name": "MEN/Inside Legs (in)",
        "is_global": true
    },
    {
        "id": 11,
        "name": "MEN/Footwear/International Conversions (in)",
        "is_global": true
    },
    {
        "id": 8,
        "name": "MEN/Footwear (cm)",
        "is_global": true
    },
    {
        "id": 9,
        "name": "MEN/Footwear (in)",
        "is_global": true
    },
    {
        "id": 12,
        "name": "WOMEN/DRESSES (cm)",
        "is_global": true
    },
    {
        "id": 14,
        "name": "WOMEN/DRESSES/International Conversions (cm)",
        "is_global": true
    },
    {
        "id": 15,
        "name": "Women/Footwear (cm)",
        "is_global": true
    },
    {
        "id": 16,
        "name": "Women/Footwear (in)",
        "is_global": true
    },
    {
        "id": 17,
        "name": "Women /Trousers (cm)",
        "is_global": true
    },
    {
        "id": 18,
        "name": "Women /Trousers (in)",
        "is_global": true
    },
    {
        "id": 19,
        "name": "Women /Trousers/International Conversions (cm)",
        "is_global": true
    },
    {
        "id": 21,
        "name": "Women/T- Shirts&Tops (in)",
        "is_global": true
    },
    {
        "id": 22,
        "name": "Women/Shorts (cm)",
        "is_global": true
    },
    {
        "id": 5,
        "name": "MEN/T-shirts & Polo Shirts (cm)",
        "is_global": true
    },
    {
        "id": 7,
        "name": "MEN/T-shirts & Polo Shirts (in)",
        "is_global": true
    },
    {
        "id": 10,
        "name": "MEN/Footwear/International Conversions (cm)",
        "is_global": true
    },
    {
        "id": 13,
        "name": "WOMEN/DRESSES (in)",
        "is_global": true
    },
    {
        "id": 20,
        "name": "Women/T- Shirts&Tops (cm)",
        "is_global": true
    },
    {
        "id": 23,
        "name": "Women/Shorts (in)",
        "is_global": true
    },
    {
        "id": 84,
        "name": "Bra (cm)",
        "is_global": true
    },
    {
        "id": 85,
        "name": "Bra (in)",
        "is_global": true
    },
    {
        "id": 86,
        "name": "Crop Top (cm)",
        "is_global": true
    },
    {
        "id": 1,
        "name": "MEN/Jeans&Trousers (cm)",
        "is_global": true
    },
    {
        "id": 87,
        "name": "Crop Top (in)",
        "is_global": true
    }
]

export const clearGuide: ITemplateDetails = {
    table: {
        "top":
            true,
        "body":
            [[[''], ['']], [[''], ['']]],
        "left":
            false,
        "image":
            "",
        "right":
            false,
        "title":
            "",
        "bottom":
            false,
        "subtitle":
            "",
        "description":
            ""
    },
    "unit": "cm"
}
