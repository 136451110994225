<template>
  <article>
    <div class="card kt-section p-3">
      <p v-show="errors_msg.attached_products" class="error">Please, select at least one product</p>
      <h3 class="kt-section__title mb-0 my-auto">Bulk Attach</h3>
      <p v-if="!checkEmptyFilter">Attached all products by filter.</p>
      <products-filter
          :product_type="filter.product_type"
          :vendor="filter.vendor"
          :tag="filter.tag"
          :change-value="onChangeValue"
      />
    </div>
  </article>
</template>

<script>
import ProductsFilter from "./ProductsFilter"
import {mapMutations, mapState} from "vuex";
import {UPDATE_FILTERS} from "../../../store/modules/guides/types";

export default {
  name: "Bulk",
  components: {ProductsFilter},
  props: {errors_msg: Object},
  data: () => ({
    checkEmptyFilter: false
  }),
  computed: {
    ...mapState({
      filter: state => state.guides.filters || {}
    })
  },
  methods: {
    ...mapMutations({
      updateFilter: `guides/${UPDATE_FILTERS}`,
    }),

    onChangeValue: function (field, newValue) {
      this.updateFilter({
        ...this.filter,
        products: [],
        [field]: newValue
      });
    }
  }
}
</script>

<style scoped>

</style>